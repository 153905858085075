@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: inherit;
  text-decoration: none;
}

@layer components {
  .ts-app-bar {
    @apply bg-white flex flex-row px-0.5 items-center border-b border-b-gray-200;
    z-index: 1002;
  }

  .ts-app-bar-left {
    @apply flex flex-row h-14 items-center px-4 gap-8;
  }

  .ts-app-bar-link {
    @apply p-4 block text-gray-700;
  }

  .ts-app-bar-link-active {
    @apply text-brand;
  }

  .ts-sidebar {
    @apply overflow-y-auto;
    z-index: 1001;
  }

  .comic-sans {
    font-family: 'Comic Sans MS';
    transform: rotate(30deg);
    opacity: 0.3;
    z-index: 10;
  }

  .ts-app-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  
  
  .ts-app-bar-right {
    display: flex;
    align-items: center;
  }

  .center {
    margin: auto;
    width: 60%;
    border: 3px solid #73AD21;
    padding: 10px;
  }


  
}